import React from "react"

const Footer = () => {
  return (
    <>
      <footer>
        <div className="copyrights">
          <div className="container">
            <div className="copyrights-inner">
              <a
                className="navbar-brand"
                href="https://mammothrc.pl/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../assets/images/logo-mammoth.svg")}
                  alt=""
                />
              </a>
              <p>
                &copy; {new Date().getFullYear()} Akademia RC All
                rights reserved.
              </p>
              <ul>
                <li>
                  <a href="#oferta">Oferta</a>
                </li>
                <li>
                  <a href="#branding">Branding</a>
                </li>
                <li>
                  <a href="#dla-kogo">Dla kogo</a>
                </li>
                <li>
                  <a href="#modele">Modele</a>
                </li>
                <li>
                  <a href="#kontakt">Kontakt</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
