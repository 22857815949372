import React from "react"
import { Link } from "gatsby"
import $ from "jquery"
import Scrollspy from "react-scrollspy"

class Header extends React.Component {
  state = {
    isMenuOpen: false,
  }

  constructor(props) {
    super(props)
    this.handleCloseMenu = this.handleCloseMenu.bind(this)
  }

  handleCloseMenu() {
    this.setState(isMenuOpen => {
      return { isMenuOpen: false }
    })
  }

  componentDidMount() {
    $(window).scroll(function () {
      if ($(window).width() > 768) {
        if ($(window).scrollTop() > 75) {
          $("header").addClass("shrink")
        } else {
          $("header").removeClass("shrink")
        }
      } else {
        if ($(window).scrollTop() > 1) {
          $("header").addClass("shrink")
        } else {
          $("header").removeClass("shrink")
        }
      }
    })

    $("#handleSubmitHeader").click()
  }

  render() {
    const { isMenuOpen } = this.state
    if (typeof window !== "undefined") {
      if (isMenuOpen === true) {
        document.body.classList.add("appleFix")
      } else {
        document.body.classList.remove("appleFix")
      }
    }
    return (
      <header>
        <div className="container">
          <nav className={isMenuOpen ? `menu-open` : ``}>
            <Link to="/" className="navbar-brand">
              <img src={require("../assets/images/logo.svg")} alt="" />
            </Link>
            <Scrollspy
              items={["oferta", "branding", "dla-kogo", "kontakt"]}
              currentClassName="current"
              offset={80}
            >
              <li>
                <a href="/#oferta" onClick={this.handleCloseMenu}>
                  Oferta
                </a>
              </li>
              <li>
                <a href="/#branding" onClick={this.handleCloseMenu}>
                  Branding
                </a>
              </li>
              <li>
                <a href="/#dla-kogo" onClick={this.handleCloseMenu}>
                  Dla kogo
                </a>
              </li>
              <li>
                <Link to="/modele-terenowe/">Modele</Link>
              </li>
              <li>
                <a href="/#kontakt" onClick={this.handleCloseMenu}>
                  Kontakt
                </a>
              </li>
            </Scrollspy>
            <button
              type="button"
              aria-label="Open Menu"
              className={`hamburger hamburger--squeeze ${
                isMenuOpen ? "is-active" : ""
              }`}
              onClick={() =>
                this.setState(prevState => {
                  return { isMenuOpen: !prevState.isMenuOpen }
                })
              }
            >
              {Array(4)
                .fill(null)
                .map((item, index) => (
                  <span key={index} />
                ))}
            </button>
          </nav>
        </div>
      </header>
    )
  }
}

export default Header
