import React from "react"

import Header from "./header"
import Footer from "./footer"

import "../assets/sass/main.scss"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <>
        <Header />
        {children}
        <Footer />
      </>
    )
  }
}

export default Layout
